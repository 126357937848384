/*
 * DO NOT IMPORT `index.css`!!!
 * `yarn build:styles` complies it into `index.build.css`.
 * Import `index.build.css` instead.
*/
@tailwind base;
@tailwind components;
@tailwind utilities;

svg {
  fill: currentColor;
}

/* Toast Container */
.Toastify__toast--success > .Toastify__toast-body > .Toastify__toast-icon {
  color: var(--toastify-color-success) !important;
}

.Toastify__toast--error > .Toastify__toast-body > .Toastify__toast-icon {
  color: var(--toastify-color-error) !important;
}

.Toastify__toast--warning > .Toastify__toast-body > .Toastify__toast-icon {
  color: var(--toastify-color-warning) !important;
}

.Toastify__toast--info > .Toastify__toast-body > .Toastify__toast-icon {
  color: var(--toastify-color-info) !important;
}

/* Progress bar */
@keyframes progress-animation {
  0% {
    transform: translateX(0) scaleX(0);
  }
  40% {
    transform: translateX(0) scaleX(0.4);
  }
  100% {
    transform: translateX(100%) scaleX(0.5);
  }
}
.progress-animation {
  animation: progress-animation 1s infinite ease;
  transform-origin: 0% 50%;
}

/* Sweet alert 2 */
.swal2-title {
  @apply text-2xl font-medium;
}
.swal2-styled:focus {
  @apply shadow-none;
}

input {
  box-shadow: none !important;
}

/* @font-face {
  font-family: 'Objective Regular';
  src: url('Objectivity-Regular.otf') format('opentype');
  line-height: inherit;
} */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

select:focus{
  box-shadow: none;
}

.react-select__menu {
  background-color: white !important;
}

ul {
  scrollbar-width: thin;
  scrollbar-color: #cccccc #f2f2f2;
}

ul::-webkit-scrollbar {
  width: 8px;
}

ul::-webkit-scrollbar-track {
  background: white;
}

ul::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 4px;
}

ul::-webkit-scrollbar-thumb:hover {
  background-color: black;
}